import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TbCat, TbBrandPython, TbBrandJavascript } from 'react-icons/tb';
import { SiLua } from 'react-icons/si';
import { saveAs } from 'file-saver';
import { Bars } from 'react-loading-icons'
import './home.css';



const Home = () => {
  var [firstName, setFirstName] = useState("");
  var [lastName, setLastName] = useState("");
  const [documentState, setDocumentState] = useState(null);
  var [loading, setLoading] = useState(false);
  var [awards, setAwards] = useState();


  const fieldMappingScratch = {
    appleCatch: "Apple Catch",
    bugHunter: "Bug Hunter",
    dinoJump: "Dino Jump",
    dragonPark: "Dragon Park",
    pong: "Pong",
    underwaterAdventures: "Underwater Adventures",
    angrySharks: "Angry Sharks",
    foodFight: "Food Fight"
  };


  const fieldMappingLua = {
    jumpQuest: "Jump Quest",
    gemHunter: "Gem Hunter",
    castleConundrum: "Castle Conundrum",
    mazeBees: "Escape The Bees",
    paintballBlitz: "Paintball Blitz",
    cheeseChase: "Cheese Chase",
    soccerShowdown: "Soccer Showdown",
    saveCity: "Save the City",
  };
  const fieldMappingPython = {
    pyPong: "Python Pong",
    snake: "Snake Game",
    breakout: "Breakout",
    spaceInvaders: "Space Invaders",
    ticTacToe: "Tic-Tac-Toe",
    mazeRunner: "Maze Runner",
    hangman: "Hangman",
    flappyBird: "Flappy Bird",
    chickenCatch: "Chicken Catch",
    enemyEscape: "Enemy Escape",
    numberGuesser: "Number Guesser",
    colorClicker: "Color Clicker"
  };
  const fieldMappingFullstack = {
    aboutCapybaras: "About Capybaras",
    travelAgent: "Travel Agent",
    dartFrog: "Dart Frog",
    SquidInkStudios: "SquidInk Studios",
    FinFancy: "Fin Fancy",
    SunnySideForecast: "SunnySide Forecast",
    BuildAShip: "Build-A-Ship",
    ComfyCouchCo: "Comfy Couch Co.",
    TopFiveBooks: "Top Five Books",
    AppleArithmetic: "Apple Arithmetic",
    PotionMaker: "Potion Maker",
    Calculator: "Calculator",
    LightSequences: "Light Sequences",
    GroceryGetter: "Grocery Getter",
    VideoGameFrenzy: "Video Game Frenzy",
    PlayerUp: "Player Up",
    CreatureCascade: "Creature Cascade",
    ByteSizedTreats: "ByteSized Treats",
    timeTravelAgency: "Time Travel Agency",
    CoderGram: "CoderGram",
    Codalytics: "Codalytics",
    PistachioFactory: "Pistachio Factory",
    pirateTreasureManagement: "Pirate Treasure Management",
    SuperPetProfiles: "Super Pet Profiles",
    mineHouseConstruction: "MineHouse Construction",
    todoApp: 'Todo App',
    recipeFinder: "Recipe Finder"
  };

  const hasTrueValueScratch = Object.keys(fieldMappingScratch).some(field => documentState?.[field]);
  const hasTrueValueLua = Object.keys(fieldMappingLua).some(field => documentState?.[field]);
  const hasTrueValuePython = Object.keys(fieldMappingPython).some(field => documentState?.[field]);
  const hasTrueValueFullstack = Object.keys(fieldMappingFullstack).some(field => documentState?.[field]);


  const getAwards = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/get-student-award-models`, {
        studentID: window.location.href.split('/')[3]
    }).then((res) => {
        console.log(res)
        setAwards(res.data)
        setLoading(false)
    }).catch((err) => {
        console.log(err)
        setLoading(false)
    })
  }


  const getStudentBadge = async () => {
    var _id = window.location.href.split('/')[3];
    await axios.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
        studentID: _id
    }).then((res) => {
        console.log(res.data[0])
        setDocumentState(res.data[0])
        getAwards()
    }).catch((err) => {
        console.log(err)
        setLoading(false)
    })
  }




  const fetchUser = async () => {
    setLoading(true)
    var _id = window.location.href.split('/')[3];
    console.log(_id)
    await axios.post(`${process.env.REACT_APP_API_URL}/single-user`, {
        id: _id
    }).then((res) => {
        console.log(res)
        setFirstName(res.data.childNameFirst)
        setLastName(res.data.childNameLast)

        //setLoading(false)
        getStudentBadge()
    }).catch((err) => {
        console.log(err)

        setLoading(false)
    })
  }





  useEffect(() => {
    fetchUser()
  }, [])



  const downloadCertificate = async (id, nameOf) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/downloaded-file`, {
        _id: id
    }, { responseType: 'blob' });

    console.log(response)
    const contentDisposition = response.headers['content-disposition'];
    let fileName = `${nameOf}.pdf`;  // default filename

    // Extract filename from the content-disposition header (if available)
    if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/i);
        if (match && match[1]) fileName = match[1];
    }

    // Trigger the file download using saveAs from file-saver
    saveAs(new Blob([response.data]), fileName);
  }


  return (
    <div className='home__container'>
        {
            loading === true ?
            <div className='loading__container'>
            <Bars stroke="#98ff98" />
            </div>
            :
            <div className='resume__container'>

                <h1 className='name'>
                    {firstName} {lastName}
                </h1>

                <p className='desc'>
                As part of the comprehensive curriculum at <a target="_blank" href="https://kidslearncoding.org" >kidslearncoding.org</a>, each student undertakes a series of challenging projects that reflect real-world scenarios and cutting-edge developments in the tech industry. These projects are designed to foster technical expertise, problem-solving skills, collaboration, and innovation. Below is a showcase of {firstName}'s work, demonstrating their proficiency in software development and commitment to pushing the boundaries of technology.            
                </p>


                <div className='project__container'>
                    <h2 style={{ color: "#fcba03" }} >
                        <TbCat style={{ marginRight: "10px" }} />MIT Scratch Projects<TbCat style={{ marginLeft: "10px" }} />
                    </h2>

                    <ul>
                        {
                            hasTrueValueScratch ? (
                                Object.entries(fieldMappingScratch).map(([field, displayName]) => 
                                    documentState?.[field] ? <li key={field}>{displayName}</li> : null
                                )
                            ) : (
                                <div className='empty__container'>
                                    <h3>
                                        Soon to be completed!
                                    </h3>
                                </div>
                            )
                        }
                    </ul>
                </div>

                <div className='project__container'>

                <h2 style={{ color: "#8f1bf5" }} >
                    <SiLua style={{ marginRight: "10px" }} />Lua Game Design<SiLua style={{ marginLeft: "10px" }} />
                </h2>

                <ul>
                    {
                        hasTrueValueLua ? (
                            Object.entries(fieldMappingLua).map(([field, displayName]) => 
                                documentState?.[field] ? <li key={field}>{displayName}</li> : null
                            )
                        ) : (
                            <div className='empty__container'>
                                <h3>
                                    Soon to be completed!
                                </h3>
                            </div>
                        )
                    }
                </ul>
            </div>


            <div className='project__container'>

                <h2 style={{ color: "#d0f51b" }} >
                <TbBrandPython style={{ marginRight: "10px" }} />Python Projects<TbBrandPython style={{ marginLeft: "10px" }} />
                </h2>

                <ul>
                    {
                        hasTrueValuePython ? (
                            Object.entries(fieldMappingPython).map(([field, displayName]) => 
                                documentState?.[field] ? <li key={field}>{displayName}</li> : null
                            )
                        ) : (
                            <div className='empty__container'>
                                <h3>
                                    Soon to be completed!
                                </h3>
                            </div>
                        )
                    }
                </ul>
            </div>

            <div className='project__container'>
                <h2 style={{ color: "#1b9ef5" }}>
                <TbBrandJavascript style={{ marginRight: "10px" }} />Full Stack Development<TbBrandJavascript style={{ marginLeft: "10px" }} />
                </h2>

                <ul>
                    {
                        hasTrueValueFullstack ? (
                            Object.entries(fieldMappingFullstack).map(([field, displayName]) => 
                                documentState?.[field] ? <li key={field}>{displayName}</li> : null
                            )
                        ) : (
                            <div className='empty__container'>
                                <h3>
                                    Soon to be completed!
                                </h3>
                            </div>
                        )
                    }
                </ul>
            </div>


            <div className='awards__container'>

                <h2>
                🏆Your Awards & Certificates🏆
                </h2>

                <div className='awards'>
                {
                    awards?.length == 0 ?
                            <div className='empty__container'>
                                <h3>
                                    Soon to be completed!
                                </h3>
                            </div>
                        :
                        awards?.map((award) => {
                            return (
                                <div onClick={()=>downloadCertificate(award?._id, award?.name)} className='award'>
                                    <h3>{award?.name}</h3>

                                    <p>
                                    Click to Download Certificate
                                    </p>
                                </div>
                            )
                        })
                }
                </div>

            </div>


            </div>
        }


    </div>
  )
}

export default Home